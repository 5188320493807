textarea {
  font-family: @font-family-monospace;
  background-color: @base3;
}

figcaption:extend(label all) {
}

.center-block-fix {
  .center-block();
  width: fit-content;
}
